<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import { employeeMethods, listPaging, masterMethods, checkPermission, checkPermissionName, keyMaster } from '@/state/helpers';
import Pagination from '@/components/Pagination/main.vue';
import { truncateText } from '../../../utils/format';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'パルディア社員一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Pagination,
        Layout,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            listData: [],
            listDataMasterTeam: [],
            listDataMasterGroup: [],
            listDataMasterRoles: [],
            total: 0,
            limit: 10,
            page: 1,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: ''
            },
            perPageList: listPaging,
            filter: {
                team: '',
                role: '',
                role_name: ''
            },
            checkShowFilter: false,
            configKeyword: {
                arrayTag: [],
                placeholder: '名前　名前(カナ) ...',
                isShowLable: true
            },
            isLoading: false
        };
    },
    watch: {
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        }
    },
    mounted() {
        this.getList();
        this.getListMasterGroup();
        this.getListMasterRoles();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...employeeMethods,
        truncateTextTable(text) {
            return truncateText(text);
        },
        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
            } else {
                this.configKeyword.placeholder = '名前　名前(カナ) ...';
            }
        },
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        getListMasterRoles() {
            this.listMaster('roles').then((data) => {
                this.listDataMasterRoles = data;
            });
        },
        getListMasterGroup() {
            this.listMaster(keyMaster['employee.department']).then((data) => {
                this.listDataMasterGroup = data;
            });
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        setTeam() {
            this.listDataMasterTeam = [];
            this.filter.team = '';
            let i = this.listDataMasterRoles.findIndex((x) => x.id == this.filter.role);
            if (i >= 0) {
                this.filter.role_name = this.listDataMasterRoles[i].name;
                if (this.filter.role_name === 'sale') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == 1);
                    this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                }
                if (this.filter.role_name === 'instructor') {
                    let index = this.listDataMasterGroup.findIndex((x) => x.id == 5);
                    this.listDataMasterTeam = this.listDataMasterGroup[index].data;
                }
            }
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];
            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${encodeURIComponent(value)}`);
                    }
                }

                if (this.filter) {
                    for (const [key, value] of Object.entries(this.filter)) {
                        if (key && value) query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }

            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listEmployee(query).then((data) => {
                this.listData = data.data;
                this.total = data._metadata.total;
                this.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            });
        },
        onChange() {
            this.page = 1;
        },
        search() {
            this.page = 1;
            this.getList();
        },
        clear() {
            this.form = {
                name: '',
                kana_name: '',
                email: '',
                phone_number: ''
            };
            this.filter = {
                team: '',
                role: '',
                department: ''
            };
            this.configKeyword.arrayTag = [];
            this.getList();
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">パルディア社員検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>
                                    <div v-if="checkShowFilter">
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom01">{{ $t('pageEmployee.name') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom01" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom02">{{ $t('pageEmployee.name_kana') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom02" v-model="form.kana_name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom03">{{ $t('pageEmployee.email') }}</label>
                                            <div class="w-100">
                                                <input
                                                    id="validationCustom03"
                                                    v-model="form.email"
                                                    autocomplete="off"
                                                    type="email"
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom04">{{ $t('pageEmployee.role') }}</label>
                                            <div class="col-sm-6 p-0">
                                                <select class="form-control" @change="setTeam()" v-model="filter.role">
                                                    <template v-for="item in listDataMasterRoles">
                                                        <option :key="item.id" :value="item.id" v-if="item.name !== 'partner'">
                                                            {{ $t(item.name) }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="filter.role_name !== 'part_time' && filter.role_name !== 'administrator'">
                                            <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('pageEmployee.team') }}</label>
                                            <div class="col-sm-6 p-0">
                                                <select class="form-control" v-model="filter.team">
                                                    <option v-for="item in listDataMasterTeam" :key="item.id" :value="item.id">
                                                        {{ item.value }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="col-sm-2 mw--form" for="validationCustom07">{{ $t('pageEmployee.cellphoneNumber') }}</label>
                                            <div class="w-100">
                                                <input id="validationCustom07" v-model="form.phone_number" type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            パルディア社員検索結果 <span>件数（{{ total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>{{ $t('pageEmployee.name') }}</th>
                                        <th>{{ $t('pageEmployee.role') }}</th>
                                        <th>{{ $t('pageEmployee.team') }}</th>
                                        <th>{{ $t('pageEmployee.email') }}</th>
                                        <th>{{ $t('pageEmployee.cellphoneNumber') }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template>
                                        <tr v-for="(item, k) in listData" :key="item.id">
                                            <td>
                                                {{ parseInt((page - 1) * limit) + parseInt(k + 1) }}
                                            </td>
                                            <td class="truncate-cell">
                                                {{ truncateTextTable(item.name + (item.name_kana ? item.name_kana : '')) }}
                                            </td>
                                            <td>
                                                {{ item.role ? $t(item.role.name) : '' }}
                                            </td>
                                            <td>{{ item.team ? item.team.value : '' }}</td>
                                            <td>{{ item.email }}</td>
                                            <td>{{ item.phone_number }}</td>
                                            <td align="center">
                                                <a
                                                    v-if="checkPermiss(['administrator', 'instructor', 'part_time', 'sale'], '')"
                                                    href="javascript:void(0)"
                                                    class="text-info border-right-1"
                                                    @click="goToEdit('/employee/view', item)"
                                                    >{{ $t('btn.detail') }}</a
                                                >
                                                <a
                                                    v-if="checkPermiss(['administrator', 'instructor', 'part_time'], '')"
                                                    href="javascript:void(0)"
                                                    class="text-success"
                                                    @click="goToEdit('/employee/form', item)"
                                                    >{{ $t('btn.edit') }}</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
