<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { campaignMethods, masterMethods } from '@/state/helpers';
// import Editor from "@tinymce/tinymce-vue";
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '削除してもよろしいですか。',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        // Editor,
        Layout,
        PageHeader
    },
    data() {
        return {
            title: '削除してもよろしいですか。',
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '削除してもよろしいですか。',
                    active: true
                }
            ],
            isLoading: false,
            templateDetail: {},
            documentPreviewData: {}
        };
    },
    watch: {},
    mounted() {
        if (this.$route.query.id) {
            this.getTemplateDetail();
        }
    },
    methods: {
        ...masterMethods,
        ...campaignMethods,
        getTemplateDetail() {
            let template = {
                id: this.$route.query.id
            };
            this.isLoading = false;
            this.templateGetDetail(template).then((data) => {
                if (data.code == 200 && data.data) {
                    this.templateDetail = data.data;
                    if (this.$route.query.campaign_id) {
                        this.getListMasterTemplate();
                    }
                }
            });
        },
        getListMasterTemplate() {
            let campaign = {
                campaign_id: this.$route.query.campaign_id
            };
            this.documentPreview(campaign).then((data) => {
                if (data.code == 200) {
                    this.documentPreviewData = data.data.context_data;
                    let data1 = JSON.parse(JSON.stringify(this.templateDetail.content));
                    data1 = data1.replace('《キャンペーン名》', this.documentPreviewData.name);
                    data1 = data1.replace('《キャンペーン開始》', this.documentPreviewData.campaign_start_date);
                    data1 = data1.replace('《キャンペーン事務局TEL》', this.documentPreviewData.tel);
                    data1 = data1.replace('《キャンペーン事務局Mailアドレス》', this.documentPreviewData.email);
                    data1 = data1.replace('《事務局開始》', this.documentPreviewData.secretariat_start_date);
                    data1 = data1.replace('《事務局終了》', this.documentPreviewData.secretariat_end_date);
                    data1 = data1.replace(
                        '《年末年始休み》',
                        `(${this.documentPreviewData.year_end_schedule_from} ~ ${this.documentPreviewData.year_end_schedule_to})`
                    );
                    this.templateDetail.content = JSON.parse(JSON.stringify(data1));
                }
                this.isLoading = true;
            });
        },
        goEdit(path) {
            this.$router.push({ path: path });
        },

        register() {
            let objectData = {
                campaign_id: parseInt(this.$route.query.campaign_id),
                documents: [
                    {
                        source: this.$route.query.id,
                        data: {}
                    }
                ]
            };
            this.postDocuments(objectData).then((data) => {
                if (data.code == 200) {
                    this.$router.push({ path: '/documents' }).then(() => {
                        this.$bvToast.toast(`Success`, {
                            title: `登録されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                    });
                }
                if (data.code == 500) {
                    this.$bvToast.toast(`error`, {
                        title: this.$t(data.message),
                        variant: 'danger',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                }
                if (data.code == 422) {
                    this.$bvToast.toast(`error`, {
                        title: this.$t(data.message),
                        variant: 'danger',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                }
                this.isLoading = true;
            });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" :loading="isLoading" />

        <div class="row form__body--card form__common--center" v-if="isLoading">
            <div class="col-lg-12">
                <div class="card form--sroll1 mb-0">
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-sm-3">内容</label>
                            <div class="col-sm-8">
                                <div v-html="templateDetail.content"></div>
                                <!--                      <Editor-->
                                <!--                              ref="editor"-->
                                <!--                              id="editor2"-->
                                <!--                              SetContent="SetContent"-->
                                <!--                              model-events="change keydown blur focus paste"-->

                                <!--                              v-model="templateDetail.content"-->
                                <!--                              api-key="kzelqkvma9nowaevz1qbr6y90io13sa2l5izqkin42od20k5"-->
                                <!--                              :init="{-->
                                <!--                                               content_style: 'p { margin: 0 }',-->
                                <!--                                       height: 600,-->
                                <!--                                        menubar: true,-->
                                <!--                                        plugins: [-->
                                <!--                                          'hr advlist autolink lists link image charmap',-->
                                <!--                                          'searchreplace visualblocks code fullscreen',-->
                                <!--                                          'print preview anchor insertdatetime media template',-->
                                <!--                                          'paste code help wordcount table code lists imagetools '-->
                                <!--                                        ],-->
                                <!--                                        toolbar:-->
                                <!--                                          'myCustomToolbarButton hr undo redo | formatselect | bold italic | \-->
                                <!--                                          alignleft aligncenter alignright | \-->
                                <!--                                          link image media | \-->
                                <!--                                          bullist numlist outdent indent | help code\-->
                                <!--                                          table tableinsertdialog tablecellprops tableprops | fullscreen ',-->
                                <!--                                        table_toolbar: 'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol',-->

                                <!--                                    setup: function (editor){-->
                                <!--                                      obj = editor;-->

                                <!--                                    },-->

                                <!--      }"-->
                                <!--                      />-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom__positon--fixed form__detail--common">
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button type="button" class="btn btn-light" @click="goEdit('/documents')">キャンセル</button>
                        <button type="button" @click="register()" class="btn btn-primary ml-3">出力</button>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
.item2 {
    padding-top: 5px;
}
.item2:last-child {
    border: 0 !important;
}
.item2:hover {
    background: rgba(239, 239, 239, 0.28);
}
</style>
